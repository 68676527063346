import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { ChevronIcon, Link, LinkVariant, Slider, SlidingDirections } from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { type Product } from '../../../shared/types/searchResponse';
import { type TrackingListName } from '../../enums/trackingListName';
import { CreativeName } from '../../enums/trackingPromotions';
import { handleSlidingDirections } from '../../helper/handleSlidingDirections.tracking';
import { useHighestMatchingBreakpoint } from '../../hooks';
import { Headline } from '../Headline';
import { ProductTileWrapper } from '../ProductTileWrapper';
import { productSliderConfig } from './productSliderConfig';
import styles from './ProductSlider.module.scss';

type ProductSliderProps = {
    readonly products: Product[];
    readonly title?: string;
    readonly urlTitle?: string;
    readonly url?: string;
    readonly trackingCategory?: string;
    readonly trackingListName?: TrackingListName;
    readonly dataTestId?: string;
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly verticalPosition?: number;
};

const ProductSlider = ({
    dataTestId,
    lazyLoad,
    pageType,
    products,
    title,
    trackingCategory,
    trackingListName,
    url,
    urlTitle,
    verticalPosition,
}: ProductSliderProps): JSX.Element | null => {
    const highestMatchingBreakpoint = useHighestMatchingBreakpoint();
    const sliderConfig = productSliderConfig[pageType][highestMatchingBreakpoint];

    if (!products.length || !sliderConfig) {
        return null;
    }

    const slides = products.map((product, index) => ({
        key: product.productId,
        content: (
            <div className="w-100">
                <ProductTileWrapper
                    product={product}
                    position={index + 1}
                    trackingListName={trackingListName}
                    isPortraitTile
                    lazyLoad={lazyLoad}
                />
            </div>
        ),
    }));

    const basicEventData = {
        eventName: GA4EventName.SlidePromotionItem,
        feature_category: GA4FeatureCategory.PromotionStatic,
        promotion_type: CreativeName.ProductTile,
        position_vertical: verticalPosition?.toString(),
    };

    return (
        <div data-testid={dataTestId}>
            <div className="d-flex justify-content-between flex-wrap mb-2x w-100 align-items-baseline">
                {title && <Headline title={title} />}
                {urlTitle ? (
                    <Link
                        className={`${styles.showMore} d-inline-flex align-content-center`}
                        href={url}
                        variant={LinkVariant.Brand}
                        internal
                    >
                        <span>{urlTitle}</span>
                        <ChevronIcon />
                    </Link>
                ) : null}
            </div>
            <Slider
                slides={slides}
                config={sliderConfig}
                handleShowNext={async () =>
                    handleSlidingDirections({
                        direction: SlidingDirections.Next,
                        trackingCategory,
                        eventData: {
                            ...basicEventData,
                            click_type: `Slide ${SlidingDirections.Next}`,
                        },
                    })
                }
                handleShowPrevious={async () =>
                    handleSlidingDirections({
                        direction: SlidingDirections.Previous,
                        trackingCategory,
                        eventData: {
                            ...basicEventData,
                            click_type: `Slide ${SlidingDirections.Previous}`,
                        },
                    })
                }
                classes={{ controlsContainer: 'mb-0' }}
            />
        </div>
    );
};

export { ProductSlider };

export default ProductSlider;
